import { useState } from "react";

import { Link } from "react-router-dom";

import usePasswordToggle from "../../hooks/usePasswordToggle";
import { useToGetStartedForm } from "../../hooks/useTogetStartedForm";
import { store } from "../../store";
import { useQuery } from "../../utils/useQuery";
import { Button } from "../basic/Button.component";
import { IconAlert } from "../basic/IconAlert.component";
import { Input } from "../basic/Input.component";

const SignUpForm = () => {
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const { InputType, Icon, handlePasswordVisibility } = usePasswordToggle();
    const { onSubmit, fields, isLoading, error, isEmailValid  } = useToGetStartedForm();
    const queryData = useQuery();
    const inviteEmail = queryData.get('email');

    return (
        <form
            noValidate
            onSubmit={(e) => {
                e.preventDefault();
                setShowPasswordInput(true);
                onSubmit();
                store.signUpStore.updateGetStartedValues({
                    firstName: fields.firstName.value,
                    country: fields.country.value,
                    lastName: fields.lastName.value,
                    email: fields.email.value,
                    publicAccess: fields.publicAccess.value,
                    password: fields.password.value,
                });
            }}
            className='grid grid-1-cols justify-items-stretch text-darker text-opacity-75 max-w-lg w-full mx-auto'
        >
            <div className='overflow-auto w-full'>
                <div className='space-y-5 px-1'>
                    <h1 className='md:text-base text-tiny font-semibold'>
                        Sign Up
                    </h1>
                    <Input
                        required
                        placeholder='Email*'
                        type='email'
                        {...fields.email}
                        disabled={!!inviteEmail}
                        error={fields.email.error}
                        onClick={() =>setShowPasswordInput(true)}
                    />
                    {fields.email.error && fields.email.errorMessage && <IconAlert message={error || fields.email.errorMessage} className='mt-4' />}
                    <div style={{ display: showPasswordInput ? 'block' : 'none' }}>
                        <div className='relative w-full'>
                            <Input
                                required
                                placeholder='Password*'
                                type={InputType}
                                {...fields.password}
                                isPassword={true}
                                error={fields.password.error}
                                onClick={() => isEmailValid()}
                                right={
                                    <img
                                        className='absolute bottom-3 right-4 w-4 h-4 cursor-pointer opacity-50'
                                        src={Icon}
                                        onClick={handlePasswordVisibility}
                                        alt=''
                                    />
                                }
                            /> 
                        </div>
                        {fields.password.error && fields.password.errorMessage && <IconAlert message={fields.password.errorMessage} className='mt-4' />}
                    </div>
                    <div className='flex flex-row items-center '>
                        <div className='flex flex-wrap space-x-2 text-sm whitespace-nowrap'>
                            <div
                                className='whitespace-nowrap ml-2 md:ml-4 cursor-pointer'
                            >
                               By signing up, you agree to the
                            </div>
                            <a
                                href='https://blankstate.ai/terms-conditions/'
                                target='_blank'
                                className='underline whitespace-nowrap text-bberry-blue'
                            >
                                Terms and Conditions
                            </a>
                            <p className='whitespace-nowrap'>and</p>
                            <a
                                href='https://blankstate.ai/privacy-policy/'
                                target='_blank'
                                className='underline whitespace-nowrap text-bberry-blue'
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                    <Button
                        className='w-full h-10'
                        type='submit'
                        variant='bberryBlue'
                        size='oval'
                        children='Sign Up'
                        disabled={!fields?.email?.value||isLoading}
                    />
                    <div className='flex items-center justify-center space-x-2 text-sm font-medium mt-12'>
                        <Link
                            to={'/sign-in'}
                            className='text-bberry-blue underline'
                        >
                            I have an account
                        </Link>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default SignUpForm