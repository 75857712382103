import React from 'react';

import Box from '@material-ui/core/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Link } from 'react-router-dom';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { SIZES } from '../../../../utils/constants';

type VerticalTab = {
    title: string;
    path: string;
    exactPath?: string[];
    icon?: React.ReactElement;
    onClick?: () => void;
    tour?: string;
    disabled?: boolean;
    hidden?: boolean;
};
type Props = {
    tabs: (VerticalTab | null)[];
};

const VeritcalTabLayout: React.FC<Props> = ({ tabs }) => {
    const currentPathname = window.location.pathname;
    const [value, setValue] = React.useState(currentPathname);
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const visibleTabs = tabs.filter((tab) => !tab?.hidden);

    if (visibleTabs.length === 0) return null;

    return (
        <Box
         className='analytics_left_tab'
            sx={{
                borderRight: '1px solid rgba(15, 16, 68, 0.07)',
                zIndex: 1,
            }}
        >
            <Tabs   
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChange}
                aria-label='Vertical tabs example'
                TabIndicatorProps={{
                    style: {
                        backgroundColor: 'rgba(15, 16, 68, 0.75)',
                        width: '4px',
                        height: '24px',
                        borderRadius: '50px 0px 0px 50px',
                        alignItems: 'center',
                        marginTop: '10px',
                    },
                }}
                sx={{
                    marginTop: '20px',
                    '& .MuiTabs-flexContainer': {
                        minWidth: '200px',
                    },
                }}
            >
                {visibleTabs &&
                    visibleTabs.map((element, index) => {
                        if (!element) return;
                        return (
                            <Tab
                                className='flex'
                                key={index}
                                label={
                                    <div style={{ display: 'flex', alignItems: 'start', width: '40px' }}>
                                        <span>{element.title}</span>
                                    </div>
                                }
                                value={element.path}
                                to={element.path}
                                component={Link}
                                icon={element.icon}
                                iconPosition='start'
                                sx={{
                                    textTransform: 'none',
                                    minHeight: '45px',
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    fontSize: '10px',
                                    marginInline: '8px',
                                    padding: 0,
                                    // marginRight:'50px',
                                    color: 'rgba(15, 16, 68, 0.5)',
                                    '&.MuiTab-root.Mui-selected': {
                                        color: 'rgba(15, 16, 68, 0.75)',
                                        fontWeight: '500',
                                    },
                                    whiteSpace: 'nowrap',
                                }}
                                onClick={element.onClick}
                                data-tour={element.tour}
                                disabled={element.disabled}
                            />
                        );
                    })}
            </Tabs>
        </Box>
    );
};

export default VeritcalTabLayout;
