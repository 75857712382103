import { useEffect, useState } from 'react';

import { EvaService } from '../../../../../../services/eva/eva.service';
import {
    ActionTypes,
    Conversation,
    EvalLocalStorage,
    EvaluateButtonTypes,
    EvaluateProtocolResponse,
} from '../../types';
import ConversationLength, { ConversationType } from './ConversationLength.component';
import EvaluateResponse from './EvaluateResponse.component';
import Keywords from './Keywords.component';
import { Scenario, scenarios } from './ScenarioList';
import SelectScenarios from './SelectScenarios.component';
import './style.css';
import UserMood, { userMood, UserMoodType, userMoodValues } from './UserMood.component';

const Evaluate = ({
    evaId,
    protocol,
    protocolName,
    setEvaluateHint,
    setActionType,
    setNewProtocol,
    setOrbitLoader,
}: {
    evaId?: string;
    protocol: { nuance: string; threshold: number }[];
    protocolName: string;
    setEvaluateHint: (hint: string | null) => void;
    setActionType: (type: string | null) => void;
    setNewProtocol: React.Dispatch<
        React.SetStateAction<{
            nuance: string;
            threshold: number;
            oldNuance?: string | undefined;
        }>
    >;
    setOrbitLoader: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const [conversationType, setConversationType] = useState<string>(ConversationType.Short);
    const [selectedScenario, setSelectedScenario] = useState<Scenario>();
    const [error, setError] = useState({
        scenarioError: false,
        keywordError: false,
    });
    const [userMood1, setUserMood1] = useState<UserMoodType>(UserMoodType.VeryNegative);
    const [userMood2, setUserMood2] = useState<UserMoodType>(UserMoodType.Neutral);
    const [user1Name, setUser1Name] = useState<string | null>(null);
    const [user2Name, setUser2Name] = useState<string | null>(null);
    const [globalUser1Name, setGlobalUser1Name] = useState<string | null>(null);
    const [globalUser2Name, setGlobalUser2Name] = useState<string | null>(null);
    const [keywords, setKeywords] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [evaluateResponse, setEvaluateResponse] = useState<EvaluateProtocolResponse | null>(null);
    const [buttonText, setButtonText] = useState<string | null>(null);
    const evaService = new EvaService();
    useEffect(() => {
        setOrbitLoader(loading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleUserMood1 = (value: number) => {
        const mood = userMood[value];
        setUserMood1(mood);
    };

    const handleUserMood2 = (value: number) => {
        const mood = userMood[value];
        setUserMood2(mood);
    };

    const errorChange = (key: string, value: boolean) => {
        setError((prevError) => ({
            ...prevError,
            [key]: value, // Dynamically update the specific error key
        }));
    };

    const createOrUpdateLocalStorage = (key: string, protocolName: string, value: EvalLocalStorage) => {
        const storedEval = localStorage.getItem(key);
        const evalData = storedEval ? JSON.parse(storedEval) : {};
        if (protocolName !== '') {
            evalData[protocolName] = value;
            localStorage.setItem(key, JSON.stringify(evalData));
        } else {
            evalData['blueprint eval'] = value;
            localStorage.setItem(key, JSON.stringify(evalData));
        }
    };

    const handleSimulate = async () => {
        if (evaId === undefined) return;
        errorChange('scenarioError', selectedScenario === undefined);
        if (!selectedScenario) return;
        setLoading(true);
        setEvaluateHint(null);
        setActionType(null);
        try {
            const result = await evaService.simulateEva(evaId, {
                scenarioType: selectedScenario.name,
                user1: user1Name,
                user2: user2Name,
                conversationType,
                userMood1: userMood1,
                userMood2: userMood2,
                keywords: keywords,
                protocolName: protocolName,
                protocol: protocol,
                variation: buttonText === EvaluateButtonTypes.Variation ? true : false,
            });
            if (result) {
                setLoading(false);
                //update global user name after simulate
                setGlobalUser1Name(user1Name);
                setGlobalUser2Name(user2Name);
                setButtonText(EvaluateButtonTypes.Variation);
                setEvaluateResponse(result);
                setTimeout(() => {
                    setEvaluateHint(result.evaHint);
                    setActionType(null);
                }, 1000);
                let totalScore = 0;
                const promises = result.conversation.map(async (conv: Conversation) => {
                    try {
                        const event = await evaService.getScoreofConversation(
                            result.blueprint,
                            result.trigger,
                            conv.text,
                        );
                        totalScore += event.score;
                        // Update the conversation with the returned score and flagged status
                        const updatedConv = {
                            ...conv,
                            score: event.score,
                            flagged: event.flagged,
                        };
                        // Update the conversation list in the state
                        setEvaluateResponse((prevResponse) => {
                            if (!prevResponse) return null;
                            const updatedConversations = prevResponse.conversation.map((c) =>
                                c.id === updatedConv.id ? updatedConv : c,
                            );
                            return { ...prevResponse, conversation: updatedConversations };
                        });
                    } catch (error) {
                        console.log(`Failed to get score for conversation: ${conv.id}`, error);
                    }
                });
                if (promises.length > 0) {
                    await Promise.all(promises);
                    // Update the global score in the state
                    setEvaluateResponse((prevResponse) => {
                        if (!prevResponse) return null;
                        const { globalUser1Score, globalUser2Score } = calculateUserGlobalScore(
                            prevResponse.conversation,
                        );
                        const updatedResponse = {
                            ...prevResponse,
                            globalUser1Score: globalUser1Score,
                            globalUser2Score: globalUser2Score,
                        };
                        createOrUpdateLocalStorage(evaId, protocolName, {
                            scenarioType: selectedScenario.id,
                            conversationType,
                            userMood1: userMood1,
                            userMood2: userMood2,
                            keywords: keywords,
                            evalResult: updatedResponse,
                        });
                        return updatedResponse;
                    });
                }
            }
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    //once all the score is calculated, calculate the global score for each user
    const calculateUserGlobalScore = (conversation: Conversation[]) => {
        const userNames = Array.from(new Set(conversation.map((item) => item.user)));
        const userScores: {
            [key: string]: number;
        } = {};

        userNames.forEach((userName) => {
            // Filter and calculate the average score for each user
            const scores = conversation.filter((item) => item.user === userName).map((item) => item.score);
            const totalScore = scores.reduce((sum, score) => sum + score, 0);
            userScores[userName] = scores.length ? totalScore / scores.length : 0;
        });

        return {
            globalUser1Score: user1Name ? userScores[user1Name] : userScores[userNames[0]],
            globalUser2Score: user2Name ? userScores[user2Name] : userScores[userNames[1]],
        };
    };

    //updating the evaluate response when the conversation text and score changes
    const handleEvaluateResponseChange = (conversation: Conversation[]) => {
        if (conversation && evaluateResponse) {
            const { globalUser1Score, globalUser2Score } = calculateUserGlobalScore(conversation);
            setEvaluateResponse({
                evaHint: evaluateResponse?.evaHint,
                globalUser1Score: globalUser1Score,
                globalUser2Score: globalUser2Score,
                conversation: conversation,
            });
        }
    };

    // Get the action to be performed based on the user resposne ( set as positive or negative)
    const getEvaluateAction = async (input: string, posneg: boolean) => {
        if (evaId === undefined || evaluateResponse === null) return;
        try {
            const result = await evaService.getAction(evaId, {
                posneg: posneg,
                protocolName: protocolName,
                protocol: protocol,
                input: input,
            });
            if (result) {
                const { type, parameters, ActionStr } = result.evaAction;
                const getCleanText = (text: string | undefined) => text?.replace(/['",]/g, '') || '';
                const nuanceText = getCleanText(
                    type === ActionTypes.CREATE ? parameters.nuanceText : ActionTypes.UPDATE && parameters.updatedText,
                );

                const oldNuance = type === ActionTypes.UPDATE ? getCleanText(parameters.nuanceSTR) : undefined;
                const weight = parameters.weight || 0.5;
                const newProtocol = {
                    nuance: nuanceText,
                    threshold: weight * 100,
                    oldNuance: oldNuance,
                };
                setNewProtocol(newProtocol);
                setEvaluateHint(ActionStr);
                setActionType(type);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // Get the score of the user inputed text response
    const handleUserResponseChange = async (text: string) => {
        if (evaId === undefined) return;
        try {
            return await evaService.getUserResponseScore(evaId, text, protocolName);
        } catch (e) {
            console.log(e);
        }
    };

    // Load the stored evaluation data from localStorage
    useEffect(() => {
        if (evaId === undefined) return;

        const storedEval = localStorage.getItem(evaId);
        const evalData = storedEval ? JSON.parse(storedEval) : {};

        const getProtocolData = (key: string) => evalData[key] || {};
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const updateWithProtocolData = (protocolData: any) => {
            const selectedScenario = scenarios.find((scenario) => scenario.id === protocolData.scenarioType);
            updateEvalStateVariables(
                selectedScenario,
                protocolData.conversationType || ConversationType.Short,
                protocolData.keywords || [],
                selectedScenario?.user1 || null,
                selectedScenario?.user2 || null,
                protocolData.userMood1 || UserMoodType.Neutral,
                protocolData.userMood2 || UserMoodType.Neutral,
                protocolData.evalResult || null,
                protocolData.evalResult ? EvaluateButtonTypes.Variation : EvaluateButtonTypes.Simulate,
                protocolData.evalResult?.evaHint || null,
            );
        };

        if (protocolName) {
            const protocolData = getProtocolData(protocolName);
            updateWithProtocolData(protocolData);
        } else {
            const protocolData = getProtocolData('blueprint eval');
            updateWithProtocolData(protocolData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaId, protocolName]);

    // Update the state variables with the new values
    const updateEvalStateVariables = (
        scenario: Scenario | undefined,
        conversation: string,
        keywords: string[],
        user1Name: string | null,
        user2Name: string | null,
        userMood1: UserMoodType,
        userMood2: UserMoodType,
        evalResult: EvaluateProtocolResponse | null,
        buttonText: string,
        hint: string | null = null,
    ) => {
        setSelectedScenario(scenario);
        setConversationType(conversation);
        setKeywords(keywords);
        setUserMood1(userMood1);
        setUserMood2(userMood2);
        setEvaluateResponse(evalResult);
        setUser1Name(user1Name);
        setUser2Name(user2Name);
        setButtonText(buttonText);
        setEvaluateHint(hint);
        setGlobalUser1Name(user1Name);
        setGlobalUser2Name(user2Name);
    };

    // Check if any state has changed compared to localStorage
    useEffect(() => {
        const hasChanges = () => {
            if (evaId === undefined) return;

            const storedEval = localStorage.getItem(evaId);
            const evalLocalStorageData = storedEval ? JSON.parse(storedEval) : {};
            if (!evalLocalStorageData) return true;

            // Get stored protocol data
            const protocolData = evalLocalStorageData[protocolName];
            const protocolSelectedScenario = scenarios.find((scenario) => scenario.id === protocolData?.scenarioType);
            if (!protocolData) return false;
            // Check if any of the state variables differ from localStorage data
            return (
                protocolData.conversationType !== conversationType ||
                protocolSelectedScenario !== selectedScenario ||
                protocolData.userMood1 !== userMood1 ||
                protocolData.userMood2 !== userMood2 ||
                !arraysEqual(protocolData.keywords || [], keywords)
            );
        };

        if (hasChanges()) {
            setButtonText(EvaluateButtonTypes.Simulate);
        } else {
            setButtonText(evaluateResponse ? EvaluateButtonTypes.Variation : EvaluateButtonTypes.Simulate);
        }
    }, [conversationType, selectedScenario, userMood1, userMood2, keywords, evaluateResponse, evaId, protocolName]);

    const arraysEqual = (a: string[], b: string[]) => {
        if (a.length !== b.length) return false;
        return a.every((val, index) => val === b[index]);
    };

    const handleRestart = () => {
        if (evaId === undefined) return;
        setEvaluateResponse(null);
        setEvaluateHint(null);
        setActionType(null);
        // update the local storage with the new data
        createOrUpdateLocalStorage(evaId, protocolName, {
            scenarioType: selectedScenario?.id || '',
            conversationType,
            userMood1,
            userMood2,
            keywords,
            evalResult: null,
        });
    };

    return (
        <div className='evaluateConatiner no-scrollbar'>
            <div className='evaluate-header'>
                <p className='text-sm font-medium'>
                    {' '}
                    {protocolName ? `Evaluation of ${protocolName}` : 'Blueprint Evaluation'}{' '}
                </p>
            </div>
            <div className='evaluate-body'>
                <SelectScenarios
                    selectedScenario={selectedScenario}
                    setSelectedScenario={setSelectedScenario}
                    error={error.scenarioError}
                    setError={errorChange}
                    setUser1Name={setUser1Name}
                    setUser2Name={setUser2Name}
                />
                {selectedScenario && (
                    <div className='space-y-2'>
                        <ConversationLength type={conversationType} setType={setConversationType} />
                        <UserMood
                            userMoodValue1={userMoodValues[userMood1]}
                            handleUserMood1={handleUserMood1}
                            userMoodValue2={userMoodValues[userMood2]}
                            handleUserMood2={handleUserMood2}
                            user1Name={user1Name}
                            user2Name={user2Name}
                        />
                        <Keywords keywords={keywords} setKeywords={setKeywords} />
                    </div>
                )}

                <button
                    className='btn-simulate'
                    onClick={() => handleSimulate()}
                    disabled={loading || !selectedScenario}
                >
                    {buttonText}
                </button>
                <hr />
            </div>
            {evaluateResponse && (
                <div className='evaluate-response-container evaluate-scrollbar mr-2'>
                    <EvaluateResponse
                        setOrbitLoader={setOrbitLoader}
                        globalUser1Score={evaluateResponse.globalUser1Score}
                        globalUser2Score={evaluateResponse.globalUser2Score}
                        globalUser1Name={globalUser1Name || 'User1'}
                        globalUser2Name={globalUser2Name || 'User2'}
                        conversation={evaluateResponse.conversation}
                        handleResponseChange={handleEvaluateResponseChange}
                        handleActionChange={getEvaluateAction}
                        handleUserResponseChange={handleUserResponseChange}
                        protocolName={protocolName}
                        onRestart={handleRestart}
                    />
                </div>
            )}
        </div>
    );
};

export default Evaluate;
