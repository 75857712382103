import { useState } from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../AppInsights';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { EvaService } from '../../../../../services/eva/eva.service';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import { EvaGraphAnalyticsEvaList } from '../../eva/types';
import HeatmapChart from '../Trends/HeatmapChart';
import { ReactComponent as InfoIcon } from './../../../../../assets/icons/info_Iocn.svg';
import ActiveEvaSelect from './ActiveEvaSelect.component';
import EmptyRisk from './EmptyRisk.component';

const Risk = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const [evaId, setEvaId] = useState('');
    const [titleList, setTitleList] = useState<EvaGraphAnalyticsEvaList[]>();
    const { getEvaListByOrganisationId } = useOrganisationForm();
    const evaService = new EvaService();

    const { isFetching, isLoading } = useQuery(['evaData', organisationId], async () => {
        try {
            const eva = await getEvaListByOrganisationId(organisationId);
            const publishedEva = eva.find((obj: EvaGraphAnalyticsEvaList) => obj.isPublished);
            if (publishedEva) {
                setEvaId(publishedEva.id);
            }

            setTitleList(eva);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const {
        data,
        isFetching: isRiskAnalyticsFetching,
        isLoading: isRiskAnalyticsLoading,
    } = useQuery(['riskAnlaytics', evaId], async () => {
        try {
            if (!evaId) return;
            const response = await evaService.getAnalyticsRisk(evaId);
            return response;
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const handleEvaChange = (id: string) => {
        setEvaId(id);
    };

    if (isFetching || isLoading || isRiskAnalyticsFetching || isRiskAnalyticsLoading) {
        return <CustomLoader />;
    }

    return (
        <div>
            {titleList && titleList.length > 0 && (
                <ActiveEvaSelect
                    evaId={evaId}
                    handleEvaChange={handleEvaChange}
                    titleList={titleList}
                    isShield={false}
                />
            )}
            {data && data.length > 0 ? (
                <div className='trends_box scroll_div'>
                    <div className='trends_protocol'>
                        <div className='trends_head mb-5 flex justify-between items-center'>
                            <h2 className='flex items-center gap-1'>
                                Risk Severity Heatmap <InfoIcon className='ml-2 cursor-pointer' />
                                <div className='navi_info_box'>
                                    This Heatmap is specific to the Protocols of Policy types.
                                    <ul>
                                        <li>
                                            Breach Severity Index (BSI): This index is based on the severity of flags
                                            relative to the policy's threshold.
                                        </li>
                                        <li>
                                            Impact: Averages the mean of the Protocol’s flag level over time across the
                                            Organization.
                                        </li>
                                    </ul>
                                </div>
                            </h2>
                        </div>
                        <div className='trends_protocol_list'>
                            <HeatmapChart scatterPlotData={data} />
                        </div>
                    </div>
                </div>
            ) : (
                <EmptyRisk
                    title='No Risk Analysis Yet'
                    description='Create a Policy Protocol'
                    buttonName='Add a Protocol'
                />
            )}
        </div>
    );
};

export default Risk;
