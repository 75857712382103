import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, FormControl, MenuItem } from '@material-ui/core';
import Select from '@mui/material/Select';
import { saveAs } from 'file-saver';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { appInsights } from '../../../../../../../AppInsights';
import { ReactComponent as SearchIcon } from '../../../../../../../assets/icons/icon-search-analytics.svg';
import { ReactComponent as EmptyLog } from '../../../../../../../assets/icons/log_empty.svg';
import { ReactComponent as SortIconsSvg } from '../../../../../../../assets/icons/sort_Icon.svg';
import { ReactComponent as Upload } from '../../../../../../../assets/icons/upload.svg';
import { useSubscriptionDetail } from '../../../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../../../hooks/useOrganisation';
import { EvaService } from '../../../../../../../services/eva/eva.service';
import { store } from '../../../../../../../store';
import { CustomLoader } from '../../../../../../basic/CustomeLoader/CustomLoader';
import { StarIcon } from '../../../../../../basic/StarIcon.component';
import { SkeletonLoading } from '../../../../../../loading/skeletonLoading';
import Nodata from '../../../../../../Nodata';
import FormSelect from '../../../../../../profile/Form/FormSelect';
import { OptionData } from '../../../../eva/components/ProtocolTypeList';
import { EvaGraphAnalyticsEvaList, EvaLogItem, GraphPoints, SubScriptionType } from '../../../../eva/types';

interface TriggerLogProps {
    evaId: string;
}
const sortBy = ['Score', 'Date'];

const downloadTime = ['All', 'Day', 'Week', 'Month', 'Year'];
type SortDirection = 'asc' | 'desc' | null;
type SortType = 'Timestamp' | 'Cat' | 'Protocols' | 'Nuances' | 'Impact';

const TriggerLog: React.FC<TriggerLogProps> = ({ evaId }) => {
    const evaService = new EvaService();
    const [page, setPage] = useState(1);
    const [evaDatas, setEvaDatas] = useState<EvaLogItem[]>([]);
    const [shortFilters, setShortFilters] = useState<string>('');
    const [scrollHeight, setScrollHeight] = useState(0);
    const [isFetchCsv, setIsFetchCsv] = useState(false);

    const [downLoadType, setDownloadType] = useState('All');
    const [searchFilters, setSearchFilters] = useState('');
    // const [sortType, setSortType] = useState('');
    const { id: organisationId } = useParams<{ id: string }>();

    const { getEvaListByOrganisationId } = useOrganisationForm();

    const [titleList, setTitleList] = useState<EvaGraphAnalyticsEvaList[]>();

    const [evaName, setEvaName] = useState<string>('');
    const [evaIds, setEvaIds] = useState('');

    const { id } = useParams<{ id: string }>();

    const subscription = useSubscriptionDetail();
    const containerRef = useRef<HTMLUListElement>(null);
    const [data, setData] = useState<GraphPoints[]>([]);

    const [sortType, setSortType] = useState<SortType | ''>('');
    const [sortDirection, setSortDirection] = useState<Record<SortType, SortDirection>>({
        Timestamp: null,
        Cat: null,
        Protocols: null,
        Nuances: null,
        Impact: null,
    });

    const [selectedShortFilters, setSelectedShortFilters] = useState({
        sortBy: 'Timestamp',
        order: 'desc',
    });

    const isShieldSubscription = subscription?.productName === SubScriptionType.SHIELD;

    const organisationName = store.spaces.currentSelectedWorkSpaceInfo.workspaceName;

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchFilters(value);
    };

    const {
        data: evaLogData,
        isLoading,
        isFetching,
        refetch,
    } = useQuery(
        ['LogData', evaIds, selectedShortFilters, searchFilters],
        () => {
            if (evaIds) {
                return evaService.getEvaLog(evaIds, selectedShortFilters, searchFilters, String(page));
            }
        },
        {
            enabled: Boolean(!!evaIds && containerRef),
        },
    );

    useEffect(() => {
        if (evaLogData) {
            if (page === 1) {
                setEvaDatas(evaLogData.log);
            } else {
                setEvaDatas([...evaDatas, ...evaLogData.log]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaLogData]);

    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        const logRecord = document.querySelector('.record') as HTMLElement | null;
        if (evaLogData) {
            if (page === 1) {
                setData(evaLogData.log);
            } else {
                const newData: GraphPoints[] = evaLogData.log;
                const mergedArray = [...data, ...newData];
                if (logRecord) {
                    const calculation = (page - 1) * evaLogData.perPages * logRecord.offsetHeight;
                    setScrollHeight(calculation);
                }
                setData(mergedArray);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, evaLogData]);
    useEffect(() => {
        const analyticLog = document.querySelector('.analytics-log-list');
        const handleScroll = () => {
            if (!analyticLog) return;
            analyticLog.scrollTo({
                top: scrollHeight,
                behavior: 'smooth',
            });
        };
        handleScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaDatas]);
    const scrollFunction = useCallback(
        (event: { currentTarget: { scrollHeight: number; clientHeight: number; scrollTop: number } }) => {
            const { scrollHeight, clientHeight, scrollTop } = event.currentTarget;
            let prevScrollPosition = 0;
            const scrollPosition = (Math.abs(scrollTop) + clientHeight) / scrollHeight;

            setPage((pre: number) => {
                if (evaLogData?.totalPage > pre) {
                    if (scrollPosition > prevScrollPosition) {
                        if (Math.abs(scrollTop) + clientHeight > scrollHeight - 10) {
                            return pre + 1;
                        }
                    }
                }
                return pre;
            });
            prevScrollPosition = scrollPosition;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [evaLogData?.totalPage],
    );

    const { isFetching: isFetchingdata, isLoading: isLoading1 } = useQuery(
        ['getEvaByOrganisationId', organisationId],
        () => getEvaListByOrganisationId(organisationId),
        {
            onSuccess: (result) => {
                if (result.id !== null) {
                    if (result) {
                        const publishedEva = result?.find((obj: EvaGraphAnalyticsEvaList) => obj.isPublished);
                        if (publishedEva) {
                            setEvaIds(publishedEva.id);
                        }
                        setTitleList(result);
                    }
                }
            },
            onError: (error: Error) => {
                appInsights.trackException({ error: error });
            },
        },
    );

    const {
        data: evaCsvLogData,
        isLoading: csvLoading,
        isFetching: csvFetching,
        refetch: csvFetch,
    } = useQuery(
        ['csv', evaIds, downLoadType],
        () => {
            if (evaIds) {
                return evaService.getEvaCsvLog(evaIds, downLoadType);
            }
        },
        {
            enabled: false, // Disable automatic fetch
        },
    );

    const handleChangeDownLoadType = (name: string) => {
        setDownloadType(name);
        setIsFetchCsv(true);
    };

    useEffect(() => {
        if (isFetchCsv) {
            csvFetch(); // Manually trigger the API call
        }
    }, [downLoadType, isFetchCsv, evaId, csvFetch]);

    const formatName = (name: string) => {
        return name?.replace(/[^a-zA-Z0-9]/g, '_').replace(/_+$/, '');
    };

    useEffect(() => {
        const formattedEvaName = evaCsvLogData?.eva ? formatName(evaCsvLogData.eva) : '';

        if (isFetchCsv && evaCsvLogData && evaCsvLogData.xlsBuffer && evaCsvLogData.xlsBuffer.data) {
            const xlsBuffer = evaCsvLogData.xlsBuffer.data;

            // Convert binary data to workbook
            const workbook = XLSX.read(new Uint8Array(xlsBuffer), { type: 'array' });

            // Write the workbook to binary .xls format
            const wbout = XLSX.write(workbook, { bookType: 'xls', type: 'array' });

            // Create a Blob with the MIME type for .xls files
            const blob = new Blob([wbout], { type: 'application/vnd.ms-excel' });

            // Use FileSaver to save the file
            saveAs(blob, `${formattedEvaName}`);

            // Reset fetch status
            setIsFetchCsv(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetchCsv, evaCsvLogData]);

    const handleSortClick = (type: SortType) => {
        setPage(1);
        setSortType((prevType) => {
            const newDirection =
                sortType === type
                    ? sortDirection[type] === 'asc'
                        ? 'desc'
                        : 'asc' // Toggle the direction if the same type is clicked
                    : 'asc'; // Default to 'asc' if a new type is clicked
            setSortDirection((prevState) => ({
                ...prevState,
                [type]: newDirection,
            }));
            setSelectedShortFilters({
                sortBy: type,
                order: newDirection,
            });
            return type;
        });
    };

    useEffect(() => {
        if (titleList && !evaIds) {
            if (!evaIds) {
                setEvaIds(titleList && titleList?.length > 0 ? titleList[0]?.id : '');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaId, titleList]);

    const HandleEvaChange = (id: string) => {
        setEvaIds(id);
        if (titleList) {
            const evaName = titleList?.find((item) => item.id === id);
            if (evaName) {
                setEvaName(evaName?.name);
            }
        }
    };

    const handleButtonClick = async () => {
        try {
            await evaService.getCronData();
        } catch (error) {
            console.error('Error fetching new protocol type:', error);
        }
    };
    if (isLoading || isFetching || isFetchingdata) {
        return <CustomLoader />;
    }

    return (
        <>
            {titleList && titleList?.length > 0 && (
                <div className='title-box'>
                    <FormControl className='select_drop_down'>
                        <Select value={evaIds} onChange={(e) => HandleEvaChange(e.target.value)}>
                            {titleList?.map((item) => {
                                return (
                                    <MenuItem
                                        className={`${
                                            item.isArchived && !item.isPublished && isShieldSubscription
                                                ? 'disable'
                                                : ''
                                        }`}
                                        value={item?.id}
                                    >
                                        E.V.A {item?.name} Trends
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            )}

            {evaDatas.length === 0 || (titleList?.length === 0 && evaLogData?.isLogExist === null) ? (
                <Nodata
                    button={
                        <Link to={`/organisation/${id}/members`}>
                            <button className='navi_btn'> Review Members </button>
                        </Link>
                    }
                    title={evaLogData?.msg || 'No Logs Available'}
                    description={
                        <p>
                            Make sure <span> {organisationName} </span> Members enabled <b>EVA</b>
                        </p>
                    }
                    EmptyImage={EmptyLog}
                />
            ) : (
                <>
                    {evaDatas.length === 0 && evaLogData?.isLogExist === false ? (
                        <>
                            <Nodata
                                button={
                                    <Link to={`/organisation/${id}/members`}>
                                        <button className='navi_btn'> Review Members </button>
                                    </Link>
                                }
                                title={evaLogData?.msg || 'No Logs Available'}
                                description={
                                    <p>
                                        Make sure <span> {organisationName} </span> Members enabled <b>EVA</b>
                                    </p>
                                }
                                EmptyImage={EmptyLog}
                            />
                        </>
                    ) : (
                        <>
                            <Box className='card bg-primary-darker rounded-container   border logs_box  p-6 mt-6'>
                                <div className='flex justify-between relative items-center'>
                                    <p className='text-md text-white font-medium'>Logs</p>

                                    <>
                                        <div className='justify-end flex log_filter_select '>
                                            <FormSelect
                                                startIcon={<Upload />}
                                                entries={isShieldSubscription ? [] : downloadTime}
                                                value={downLoadType === 'All' ? `${downLoadType} Time` : downLoadType}
                                                onChange={(e) => handleChangeDownLoadType(e)}
                                                edit
                                                placeholder='All Time'
                                                className={`${
                                                    isShieldSubscription
                                                        ? 'pointer-events-none  log-select_disable'
                                                        : ''
                                                } log-select '`}
                                                marginTop={42}
                                                disabled={isShieldSubscription}
                                                selectedShortFilters={selectedShortFilters}
                                            />
                                            {isShieldSubscription && (
                                                <div className='starIcon cursor-pointer'>
                                                    <Link to={`/organisation/${organisationId}/change-tier`}>
                                                        <StarIcon isHover={true} />
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                </div>

                                <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-6'>
                                    <div className='col-span-2'>
                                        <div className='relative'>
                                            <input
                                                type='text'
                                                placeholder='Search'
                                                value={searchFilters}
                                                onChange={handleChange}
                                                className='search-input py-2 px-4 block w-full rounded-md text-normal'
                                            />
                                            <SearchIcon className='absolute left-4 top-1/2 transform -translate-y-1/2' />
                                        </div>
                                    </div>
                                </div>
                                <div className='log_header'>
                                    <ul className='flex items-center'>
                                        {['Timestamp', 'Cat', 'Protocols', 'Nuances', 'Impact'].map((type) => (
                                            <li
                                                key={type}
                                                className={`flex items-center ${
                                                    sortType === type
                                                        ? sortDirection[type as SortType] === 'asc'
                                                            ? 'icon1'
                                                            : sortDirection[type as SortType] === 'desc'
                                                            ? 'icon2'
                                                            : ''
                                                        : ''
                                                } ${sortType === type ? 'sort' : ''}`}
                                            >
                                                <span
                                                    className='flex items-center gap-2 cursor-pointer'
                                                    onClick={() => handleSortClick(type as SortType)}
                                                >
                                                    {' '}
                                                    {type}
                                                    <SortIconsSvg />{' '}
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <ul
                                    className='analytics-log-list scroll-thin '
                                    ref={containerRef}
                                    onScroll={scrollFunction}
                                >
                                    {isLoading || (isFetching && page == 1) ? (
                                        <SkeletonLoading background='white' height='50px' />
                                    ) : (
                                        <>
                                            {evaDatas.length === 0 && evaLogData?.isLogExist === true ? (
                                                <>
                                                    {' '}
                                                    <p className='no_data mt-4'> {evaLogData?.msg}</p>{' '}
                                                </>
                                            ) : (
                                                <>
                                                    {evaDatas?.map((item: EvaLogItem, index: number) => (
                                                        <li key={index} className='flex items-center record'>
                                                            <p className='text-normal date_time'>
                                                                <span className='date_time'>{item.date}</span>{' '}
                                                            </p>
                                                            <p className='text-normal text-white  relative pl-2.5 flex gap-1 items-center'>
                                                                <span className='protocolType'>
                                                                    <img
                                                                        src={`${
                                                                            OptionData.find(
                                                                                (obj) => obj.name === item.protocolType,
                                                                            )?.icon
                                                                        }`}
                                                                        alt=''
                                                                    />{' '}
                                                                    {/* {item.protocolType} */}
                                                                </span>{' '}
                                                            </p>
                                                            <p className='text-normal text-white relative  log_dots  pl-2.5'>
                                                                <label className='overflow-hidden'>
                                                                    {' '}
                                                                    <span
                                                                        className={`${
                                                                            item.name.length > 35 ? 'scroll_span' : ''
                                                                        } name`}
                                                                    >
                                                                        {item.name}
                                                                    </span>{' '}
                                                                </label>
                                                            </p>
                                                            <p className='text-normal text-white relative  log_dots pl-2.5'>
                                                                <label className='overflow-hidden'>
                                                                    <span
                                                                        className={`${
                                                                            item.nuances.length > 35
                                                                                ? 'scroll_span'
                                                                                : ''
                                                                        }  nuances`}
                                                                    >
                                                                        {' '}
                                                                        {item.nuances}
                                                                    </span>{' '}
                                                                </label>
                                                            </p>
                                                            <p className='text-normal text-white relative   pl-2.5'>
                                                                {' '}
                                                                <span className='score'>{item.score}</span>{' '}
                                                            </p>
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {isLoading ||
                                        (isFetching && page !== 1 && (
                                            <SkeletonLoading background='white' height='30px' />
                                        ))}
                                </ul>
                            </Box>
                        </>
                    )}
                </>
            )}
            <Button onClick={handleButtonClick} style={{ display: 'none' }}>
                Click
            </Button>
        </>
    );
};

export default observer(TriggerLog);
