import { useState } from 'react';
import './analyticsLogs.css';

import { Grid } from '@material-ui/core';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../AppInsights';
import { SubscriptionProvider } from '../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import { EvaGraphAnalyticsEvaList } from '../../eva/types';
import TriggerLog from '../components/analyticsChart/components/TriggerLog';

const AnalyticsLogs: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const [evaId, setEvaId] = useState('');
    const { getEvaListByOrganisationId } = useOrganisationForm();

    const { isFetching, isLoading: loading } = useQuery(['evaData', organisationId], async () => {
        try {
            const eva = await getEvaListByOrganisationId(organisationId);
            const publishedEva = eva.find((obj: EvaGraphAnalyticsEvaList) => obj.isPublished);
            if (publishedEva) {
                setEvaId(publishedEva.id);
            }
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    if (loading || isFetching) {
        return (
            <>
                <CustomLoader />
            </>
        );
    }

    return (
        <>
            <div className='pe-5 analytics_box'>
                <SubscriptionProvider>
                    <Grid spacing={2} className='oversight_tab scroll_div'>
                        <Grid item className='graph_box'>
                            <TriggerLog evaId={evaId} />
                        </Grid>
                    </Grid>
                </SubscriptionProvider>
            </div>
        </>
    );
};

export default AnalyticsLogs;
