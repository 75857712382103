import { useState, useEffect } from 'react';

import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../../AppInsights';
import { ReactComponent as SwitchOff } from '../../../../../../assets/icons/Switch_OFF.svg';
import { ReactComponent as SwitchOn } from '../../../../../../assets/icons/Switch_On.svg';
import { ReactComponent as SwitchOnHover } from '../../../../../../assets/icons/Switch_OnHover.svg';
import useMediaQuery from '../../../../../../hooks/useMediaQuery';
import { useOrganisationForm } from '../../../../../../hooks/useOrganisation';
import { EvaService } from '../../../../../../services/eva/eva.service';
import { ROLES, SIZES } from '../../../../../../utils/constants';
import { AnimatedCircle } from '../../../../../basic/AnimatedCircle';
import { EvaType } from '../../types';
import { EvaOrbit } from '.././orbit/EvaOrbit';

interface CreateEvaModelProps {
    isCreateEvaModalOpen: boolean;
    setCreateEvaModalOpen: (value: boolean) => void;
    setPublishedEvaExists: (value: boolean) => void;
    setDraftEvaExists: (value: boolean) => void;
    labelShow: boolean;
    protocolIndex: number | null;
}

export const PublishedPlanet: React.FC<CreateEvaModelProps> = ({
    isCreateEvaModalOpen,
    setCreateEvaModalOpen,
    setPublishedEvaExists,
    setDraftEvaExists,
    labelShow,
    protocolIndex,
}) => {
    const isMobile = useMediaQuery(SIZES.MOBILE_WIDTH);

    const { id: organisationId } = useParams<{ id: string }>();
    const { getEvaByOrganisationId, makeEvaEnableDisable, getUserPermission } = useOrganisationForm();
    const [transferSwitch, setTransferSwitch] = useState<boolean>(true);
    const [evaId, setEvaId] = useState<string | undefined>();

    const evaService = new EvaService();

    const { data: userPermit } = useQuery(['getUserPermission', organisationId], () => {
        try {
            return getUserPermission(organisationId);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const { isLoading, isFetching, refetch } = useQuery(
        ['getEvaByOrganisationId', organisationId, EvaType.publish],
        () => getEvaByOrganisationId(organisationId, EvaType.publish),
        {
            onSuccess: (result) => {
                if (result.id !== null) {
                    if (result) {
                        setEvaId(result.id);
                        setTransferSwitch(result.isEvaEnable);
                    }
                }
            },
            onError: (error: Error) => {
                appInsights.trackException({ error: error });
            },
        },
    );

    const { data: evaSummary, isLoading: evaSummaryLoading } = useQuery(['getLatestEvaUpdate', evaId], () => {
        try {
            if (evaId) return evaService.getLatestEvaUpdate(evaId);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    const makeEvaEnableOrDisable = async (toggle: boolean) => {
        if (evaId) {
            const data = await makeEvaEnableDisable({ isEvaEnable: toggle }, organisationId, evaId);
            setTransferSwitch(data.isEvaEnable);
        }
    };
    useEffect(() => {
        if (!isCreateEvaModalOpen) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCreateEvaModalOpen]);

    const handleEnableChange = () => {
        if (evaId !== undefined) {
            setTransferSwitch(!transferSwitch);
            makeEvaEnableOrDisable(!transferSwitch);
        }
    };

    if (evaId === undefined) {
        return (
            <div className='outset_loader'>
                <AnimatedCircle isThinking={true} />
            </div>
        );
    }

    return (
        <div className='w-full mx-auto relative outside_eva'>
            <div className='border border-grey-light p-4 rounded-2xl flex-col eva-publish-planet-bg publish-planet-box bg-white h-full items-center relative flex justify-center'>
                {evaId === undefined && (isLoading || isFetching) ? (
                    <div style={{ height: 770, width: '100%' }}>
                        <div className='outset_loader'>
                            <AnimatedCircle />
                        </div>
                    </div>
                ) : (
                    <>
                        {isMobile ? (
                            ''
                        ) : (
                            <EvaOrbit
                                enablePlanet={transferSwitch}
                                labelShow={labelShow}
                                isCreateEvaModalOpen={isCreateEvaModalOpen}
                                setCreateEvaModalOpen={setCreateEvaModalOpen}
                                evaId={evaId}
                                protocolIndex={protocolIndex}
                                setPublishedEvaExists={setPublishedEvaExists}
                                setDraftEvaExists={setDraftEvaExists}
                            />
                        )}
                        {!isMobile && (
                            <div className='eva_bottom_content'>
                                {evaSummaryLoading ? (
                                    <div className='flex mt-5 '>
                                        <div className='text-animation-load mx-auto'>
                                            <div className='gradient1' />
                                            <div className='gradient2' />
                                        </div>
                                    </div>
                                ) : (
                                    <div className='publish-eva-dynamic-text mt-5'>
                                        <p className='text-normal bg-clip-text bg-text-gradient text-fill-transparent text-center'>
                                            {evaSummary}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                <button
                    className='flex items-center switch_btn '
                    onClick={handleEnableChange}
                    disabled={userPermit === ROLES.Member || userPermit === ROLES.Architect}
                >
                    {transferSwitch ? (
                        <div className='switchOn_btn'>
                            <SwitchOn className='switchOn' /> <SwitchOnHover className='switchOnHover' />{' '}
                        </div>
                    ) : (
                        <SwitchOff />
                    )}
                </button>
            </div>
        </div>
    );
};
